(function( $ ){

    // Test for placeholder support
    $.support.placeholder = (function(){
        var i = document.createElement('input');
        return 'placeholder' in i;
    })();

    var floatLabelSettings, floatLabelMethods = {
        init : function(options) {

            // Set default options
            floatLabelSettings = $.extend({
                // These are the defaults.
                fieldClass:     'form--float-labels__field',
                activeClass:    'form--float-labels__field--shrink',
                fieldWrapper:   'div',
                inputs:         'input[type="text"], input[type="email"], input[type="number"], input[type="tel"],' +
                    ' input[type="url"], input[type="password"], textarea, select:not([multiple])'
            }, options );


            // On page load activate the float labels, if it's supported
            if ( $.support.placeholder ) {

                // When initially calling this, activate the float labels
                this.find( floatLabelSettings.inputs ).each(function(){
                    var $input = $(this),
                        $parent = $input.closest( floatLabelSettings.fieldWrapper );

                    if ( $input.closest( floatLabelSettings.fieldWrapper ).data( 'form-float-label' ) === 'complex' ) {
                        $parent = $input.parent();
                    }

                    // Add the base class to each field
                    $parent.addClass( floatLabelSettings.fieldClass );

                    floatLabelMethods.run( $input, $parent );
                    floatLabelMethods.setOnClick( $input, $parent );
                });
            }

        },

        run : function( element, parent ) {
            if ( element.attr('type') === 'hidden' ) {
                return;
            }

            if ( element.val() === '' ) {
                parent.removeClass( floatLabelSettings.activeClass );
            } else {
                parent.addClass( floatLabelSettings.activeClass );
            }
        },

        setOnClick: function( element, parent ) {
            // When clicking into a field, activate float labels
            $(document).on('keyup blur focus click change', element, function (event) {
                floatLabelMethods.run( element, parent );
            });
        }
    };

    $.fn.formFloatLabels = function(methodOrOptions) {
        if ( floatLabelMethods[methodOrOptions] ) {
            return floatLabelMethods[ methodOrOptions ].apply( this, Array.prototype.slice.call( arguments, 1 ));
        } else if ( typeof methodOrOptions === 'object' || ! methodOrOptions ) {
            // Default to "init"
            return floatLabelMethods.init.apply( this, arguments );
        } else {
            $.error( 'Method ' +  methodOrOptions + ' does not exist on jQuery.formFloatLabels' );
        }
    };


})( jQuery );